//  ======================================================
//  SETTINGS.SCSS
//  All varibles live here
//  ======================================================

@import 'mixins';

// BREAKPOINTS - values also need to be changed in responsiveScripts.js

// MIN WIDTH VALUES
$screen-xl:                  1400px;
$screen-lg:                  1200px;
$screen-md:                  992px;
$screen-sm:                  768px;
$screen-xs:                  480px;

// COLORS

$gray-base:              	#000;
$gray-darker:            	#222;
$gray-dark:              	#141414;
$gray:                   	#666;
$gray-light:             	#F5F5F5;
$gray-lighter:           	#efefef;

$gray-background:           #F5F5F5; //Update based on concepts
$gray-border:               #d4d4d4; //Update based on concepts

$brand-one:         		#ABEA67; // light green
$brand-two:         		#17566C; // Orient

$emphasis-primary:          $brand-one;
$emphasis-secondary:        $brand-two;
$emphasis-tertiary:         $gray-dark;

$alert-green:				#4C832C;
$alert-yellow:				#F0B844;
$alert-blue:				#245EB2;
$alert-red:					#DB2C17;

$body-bg:					#F5F5F5; // default background color on body

// TEXT STYLES

$text-color:              	#141516;

$system-fonts: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'Segoe UI', 'lucida grande', 'helvetica neue', helvetica, 'Fira Sans', roboto, noto, 'Droid Sans', cantarell, oxygen, ubuntu, 'franklin gothic medium', 'century gothic', 'Liberation Sans', sans-serif;
$font-family-sans-serif: "Open Sans", $system-fonts;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;

$font-family-alternate: 'Open Sans Condensed', sans-serif;
$font-family-alternate-heading: 'Open Sans Condensed', sans-serif;

$font-size-base:          	18px;
$font-weight-base:          normal;
$line-height-base:        	1.5;

$bold-text-font-family:     inherit;
$bold-text-font-weight:     bold;

$headings-font-family:    	'Open Sans Condensed', sans-serif;
$headings-font-weight:    	inherit;
$headings-line-height:    	inherit;
$headings-color:          	#141516;

$font-size-large:         	em(21px); //$font-size-base + 2
$font-size-small:         	em(16px); //$font-size-base - 2

$font-size-h1:            	em(72px);
$text-color-h1: 		  	inherit;
$font-weight-h1: 		  	bold;

$font-size-h2:            	em(40px);
$text-color-h2: 		  	inherit;
$font-weight-h2: 		  	bold;

$font-size-h3:            	em(34px);
$text-color-h3: 		  	inherit;
$font-weight-h3: 		  	bold;

$font-size-h4:            	em(28px);
$text-color-h4: 		  	inherit;
$font-weight-h4: 		  	bold;

$font-size-h5:              em(22px);
$text-color-h5:             inherit;
$font-weight-h5:            bold;

$font-size-h6:              em(18px);
$text-color-h6:             inherit;
$font-weight-h6:            bold;

$font-size-caption:              $font-size-h3;
$text-color-caption:             inherit;
$font-weight-caption:            $bold-text-font-weight;

$font-size-intro:         	$font-size-large;
$text-color-intro: 		  	inherit;
$font-weight-intro:       	inherit;

$link-color:              	#2E738B;
$link-font-weight: 	      	bold;
$link-decoration: 	      	none;
$link-hover-color:        	darken($link-color, 5%);
$link-hover-decoration:   	underline;
$link-visited-color:        $link-hover-color;
$link-visited-hover-color:  $link-visited-color;


// NEWS MODULE COLOURS
$news-link-colour:                   $link-color;
$news-back-to-search:                $news-link-colour;
$news-back-to-search-arrow:          $news-back-to-search;
$news-post-date:                     $gray;

$news-subscribe-button-background:   $brand-one;
$news-subscribe-button-foreground:   color-contrast($news-subscribe-button-background, $colors: (#fff, #000), $tolerance: 0);
$news-primary-button-background:     $brand-one;
$news-primary-button-foreground:     color-contrast($news-primary-button-background, $colors: (#fff, #000), $tolerance: 0);
$news-like-button-background:        $gray-lighter;
$news-like-button-foreground:        $news-link-colour;
$news-like-button-heart:             $news-like-button-foreground;
$news-post-comment:                  $news-link-colour;
$news-login-button-background:       $gray-lighter;
$news-login-button-foreground:       $news-link-colour;
$news-post-button-background:        $brand-two;
$news-post-button-foreground:        color-contrast($news-post-button-background, $colors: (#fff, #000), $tolerance: 0);
$news-reply-button-foreground:       $news-link-colour;

$news-date-highlight:                $brand-one;
$news-range-highlight:               #dfecec;

$news-pagination-background:        white;
$news-pagination-foreground:        $news-link-colour;
$news-pagination-active-background: $news-pagination-foreground;
$news-pagination-active-foreground: color-contrast($news-pagination-active-background, $colors: (#fff, #000), $tolerance: 0);

$news-toast-background:             $brand-one;
$news-toast-top:                    100px;

$news-carousel-background:          $brand-one;

// COMPONENTS

$border-radius-base: .25rem;
$border-radius-large: .5rem;
$border-radius-small: .125rem;

$box-shadow-base: 0 .25rem .5rem rgba(black,.2);
$box-shadow-large: 0 .5rem 1rem rgba(black,.2);
$box-shadow-small: 0 .125rem .25rem  rgba(black,.2);

$transition-base: .3s cubic-bezier(0.23, 1, 0.32, 1);

/// TABLES

$table-bg:                      transparent;
$table-border:                  solid 2px #DCDCDC; //ie solid 2px color
$table-border-radius:           0; // add border-collapse: separate if you want rounded corner

$table-text-color:              inherit;
$table-cell-padding:            18px 30px 15px; 
$table-row-bg:                 	#F2F2F2;
$table-altrow-bg:               white;
$table-cell-border-color:       rgba(255, 255, 255, 0);

$table-titlerow-bg:             white;
$table-titlerow-padding:        18px 30px 15px;
$table-titlerow-text-align:     left;
$table-titlerow-text-color:     black;
$table-titlerow-font-size:      em(18px);
$table-titlerow-font-weight:    bold;
$table-titlerow-border-bottom:  solid 7px #236B84;

// STRUCTURE
$container-width: 94%;
$container-padding: 3%;
$container-max-width: 1400px;
$container-max-width-wide: 1600px;

// SEARCH WIDGET
$search-height:					40px;  // .search, searchText, .goButton
$search-bg:						#f7f7f7;
$search-border-color:			$gray-light;
$search-border-radius:			$border-radius-base;
$search-text-color:				$text-color;
$search-text-padding:			3px 10px;
$search-font-size:				inherit;
$search-button-bg:				transparent; // button color

$topnav-font-size: em(15px) !default;
$topnav-line-height: inherit !default;
$topnav-font-weight: normal !default;
$topnav-link-color: #141414 !default;
$topnav-link-decoration: none !default;
$topnav-link-hover-decoration: underline !default;

// INTERIOR CONTAINER WIDTHS
$subnav-width:					326px;  // $subnav-width + $content-width must = 100%;
$content-width:					calc(100% - #{$subnav-width});  // #contentInt width
$right-col-width:				$subnav-width; // #intFloatRight width;
$actions-width:					200px;
